







































































import Vue from 'vue';
import vTable from '@/components/table.vue';
import { RootState } from '@/store';
import { mapActions, mapGetters } from 'vuex';
import VForm from '@/types/vuetify';
import { JournalType } from '@/types/sqlite';

interface JournalListData {
	dialog: boolean;
	journalName: string | null;
	journalText: string | null;
	valid: boolean;
	editing: JournalType | null;
}

export default Vue.extend({
	name: 'JournalList',
	components: {
		vTable
	},
	data(): JournalListData {
		return {
			dialog: false,
			journalName: null,
			journalText: null,
			valid: false,
			editing: null
		};
	},
	computed: {
		...mapGetters(['getSelectedJournal', 'projectIsSaving']),
		journals(): {
			headers: { [prop: string]: string }[];
			data: JournalType[];
		} {
			return {
				headers: [
					{ value: 'name', text: 'Name' },
					{ value: 'actions', text: 'Actions' }
				],
				data: (this.$store
					.state as RootState).projectCurrent.journals.data.filter(
					(v) => v.status === 1
				)
			};
		}
	},
	methods: {
		...mapActions(['addJournal', 'updateJournalName', 'deleteJournal']),
		selectionChange({ item, value }: { item: JournalType; value: boolean }) {
			// TODO: move this function to an action
			if (value) {
				this.$router.push({
					name: 'journal',
					params: { journalId: item.name }
				});
			} else {
				this.$router.push({ name: 'journal-list' });
			}
		},
		openNewJournal() {
			this.editing = null;
			this.journalName = '';
			this.journalText = '';
			if (this.$refs.form) (this.$refs.form as HTMLFormElement).reset();
			this.dialog = true;
		},
		openEditJournal(item: JournalType) {
			this.editing = item;
			this.journalName = this.editing.name;
			this.journalText = this.editing.journal;
			this.dialog = true;
		},
		save() {
			const isFormValidated = (this.$refs.form as VForm).validate();
			if (!isFormValidated) return;
			if (this.editing) {
				const item = { name: this.journalName, journal: this.journalText };
				this.updateJournalName({
					newItem: item,
					oldItem: this.editing
				});
				this.dialog = false;
			} else {
				this.addJournal({ name: this.journalName, journal: this.journalText });
				this.dialog = false;
			}
		}
	}
});
